.sidebar {
  width: 14rem;
  height: 85.9vh;
  background-color: transparent;
  color: #fff;
  font-family: Montserrat, sans-serif;
  padding-right: 0;
  padding-top: 0;
}
.ant-layout-sider{
  background: var(--Gradient1, linear-gradient(180deg, #212121 0, #101010 100%));
}
.normal-menu {
  padding-left: 0;
  margin: 1rem 0;
}
.normal-menu li {
  padding: 10px;
  cursor: pointer;
  list-style: none;
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 2.25rem;
  margin: 1rem 0 1rem 1rem;
  color: #fff;
}

.normal-menu .icon {
  fill: none; /* Initial color of the SVG */
  transition: fill 0.3s; /* Smooth transition effect */
  /* stroke: url(#linear-gradient); */
  stroke-linecap: round;
  stroke-linejoin: round;
  padding-right: 25px;
  width: 13px;
  height: 13px;
  stroke: white;
}
.dropdown .icon {
  fill: none; /* Initial color of the SVG */
  transition: fill 0.3s; /* Smooth transition effect */
  /* stroke: url(#linear-gradient); */
  stroke-linecap: round;
  stroke-linejoin: round;
  padding-right: 25px;
  width: 13px;
  height: 13px;
  stroke: white;
}
.original-icon {
  fill: none; /* Initial color of the SVG */
  transition: fill 0.3s; /* Smooth transition effect */
  stroke: url(#linear-gradient);
  stroke-linecap: round;
  stroke-linejoin: round;
  padding-right: 25px;
  width: 13px;
  height: 13px;
}
/* Add a hover effect to change the color on hover */
/* .normal-menu .icon:hover {
  stroke: white;
} */
.normal-menu .icon:active {
  stroke: white;
}
.normal-menu .icon:focus {
  stroke: white;
}
/* .normal-menu li:hover  {
  background: #34373d;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(4.5px);
  backdrop-filter: blur(4.5px);
  border-radius: 10px 0px 0px 10px;
  
} */
.normal-menu li:active {
  border-radius: 10px 0px 0px 10px;
  background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
}
.navbar-active {
  border-radius: 10px 0px 0px 10px;
  background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
}
.normal-menu img {
  padding-right: 25px;
  width: 13px;
  height: 13px;
}
.dropdown {
  margin-left: 1rem;
  border-radius: 10px 0px 0px 10px;
  /* background: #34373d; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  backdrop-filter: blur(4.5px);
  z-index: 1;
}
.dropdown.open .dropdown-menu {
  display: block;
}

/* .dropdown img {
  padding-right: 1.5rem;
  width: 13px;
  height: 13px;
} */

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  /* text-align: left; */

  width: 100%;
  text-align: right;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 1.7rem 10px 1rem;
  border-radius: 10px 0px 0px 10px;
  background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
}
.navbar-inactive {
  background: none;
  border: none;
  cursor: pointer;
  /* text-align: left; */
  width: 100%;
  text-align: right;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px 0 10px 0;
  border-radius: 10px 0px 0px 10px;
  background: none;
  display: flex;
  align-items: center;
  /* margin-left: 16px; */
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  color: #c5c5c5;
  background: #34373d;
  border-radius: 0 0px 0px 10px;
  border-top-left-radius: -10%;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  width: inherit;
}
.dropdown-menu .active {
  text-align: center;
}
.dropdown-menu li {
  padding: 10px;
  color: #c5c5c5;

  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding-left: 4.8rem;
}

.dropdown-menu li:hover {
  background-color: #444;
  border-radius: 10px 0px 0px 10px;
}
.dropdown-menu li.active {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 2.5rem;
}
.dropdown-menu img.active {
  display: none;
}
.ant-layout-sider {
  height: 86vh;
}
.ant-menu {
  background-color: transparent;
}
.ant-menu-item {
  text-align: left;
}
.ant-menu-item-icon {
  width:1rem ;
}
.ant-menu-title-content {
  padding-left: 2rem;
}
.ant-menu-item-selected {
  background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
}

@media screen and (max-width: 1280px) and (max-height: 603px) {
  .sidebar {
    /*width: 18rem; Increase the width as needed for the screen size */
    height: 75vh; /* Adjust the height as needed */
  }
  .normal-menu li {
    padding-left: 0;
  }
  .normal-menu img {
    padding-right: 15px; /* Adjust the padding as needed */
  }
  .normal-menu {
    padding-left: 1rem;
  }
  .normal-menu img {
    padding-right: 2rem;
  }
  .normal-menu li {
    /* width: 80%; */
    margin-left: 0px;
    padding-left: 2rem;
  }
  .dropdown-toggle {
    padding: 10px 0 10px 2rem; /* Adjust the padding as needed */
    text-align: left;
  }
  .dropdown-menu li {
    padding-left: 4.8rem; /* Adjust the padding as needed */
  }
  .navbar-inactive {
    text-align: left;
    /* padding-left: 2rem; */
    /* margin-right: 1rem; */
    /* width: 2.5rem; */
  }
  .ant-layout-sider {
    height: 82.5vh;
  }
}
